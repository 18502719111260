




































import { Component, Vue } from 'vue-property-decorator'
import { Toast } from 'vant'
import { GetReport } from '@/api/common'
import store from '@/store'
import { IHistory } from '@/interfaces/common'
import dayjs from 'dayjs'

@Component({
  name: 'Home'
})
export default class extends Vue {
  private number = ''
  private list: IHistory[] = []

  private created () {
    const history = window.localStorage.getItem('history')
    if (history) {
      this.list = JSON.parse(history)
    } else {
      this.list = []
    }
  }

  private formatter (value : string) {
    return value.trim()
  }

  private clear () {
    this.list = []
    window.localStorage.setItem('history', JSON.stringify(this.list))
  }

  private search (history: IHistory) {
    this.number = history.Number
    this.searchHandler()
  }

  /**
   * 搜索生产批号
   */
  private async searchHandler () {
    // 正则判断
    const patt = /^\d{8,}$/
    if (!patt.test(this.number)) {
      Toast('批号错误')
      return
    }
    try {
      Toast('加载中')
      // 从服务器获取报告
      const result = await GetReport(this.number)
      if (result.data.Code === 200) {
        // 保存到全局
        store.state.data = result.data.Data
        const index = this.list.findIndex(e => e.Number === this.number)
        if (index >= 0) this.list.splice(index, 1)
        this.list.unshift({
          Time: dayjs().format('YYYY-MM-DD'),
          Number: this.number
        })
        if (this.list.length > 10) this.list = this.list.splice(0, 10)
        window.localStorage.setItem('history', JSON.stringify(this.list))
        // 跳转到详情页
        this.$router.push({
          path: '/report',
          query: { number: this.number }
        })
      } else {
        Toast(result.data.Message)
      }
    } catch (error) {
      Toast('网络故障')
    }
  }
}
