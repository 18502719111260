import { IData } from '@/interfaces/common'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export interface IRootState {
  data: IData | undefined
}

export default new Vuex.Store<IRootState>({
})
