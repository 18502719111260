
























































































import { Component, Vue } from 'vue-property-decorator'
import { Toast } from 'vant'
import { GetReport } from '@/api/common'
import store from '@/store'
import { IData, IEntry } from '@/interfaces/common'
import dayjs from 'dayjs'

@Component({
  name: 'Report'
})
export default class extends Vue {
  private number = ''
  private data : IData = {
    Entries: []
  }

  private created () {
    const number = this.$route.query.number
    if (!number) {
      this.$router.push('/')
      return
    }
    // 201101024032
    this.number = number.toString()
    const patt = /^\d{8,}$/
    if (patt.test(this.number)) {
      if (store.state.data !== undefined && store.state.data.Report?.Number === this.$route.query.number) {
        this.data = store.state.data
      } else {
        Toast('加载中')
        this.getData()
      }
    } else {
      Toast('批号错误')
      setTimeout(() => {
        this.$router.push({ path: '/' })
      }, 500)
    }
  }

  private formatDate (date: string) {
    return dayjs(date).format('YYYY-MM-DD')
  }

  private onClickLeft () {
    this.$router.push('/')
  }

  private getValue (item: IEntry) {
    return `${item.Value}${item.UnitName}`
  }

  private getTip (item: IEntry) {
    let tip = ''
    if (item.Lower) {
      tip += `最小值:${item.Lower}`
    }
    if (item.Upper) {
      tip += `最大值:${item.Upper}`
    }
    return tip
  }

  private exportReport () {
    window.open(`//api.wms.ttpvp.com/api/report/Export/${this.number}`)
  }

  private async getData () {
    try {
      const result = await GetReport(this.number)
      if (result.data.Code === 200) {
        store.state.data = result.data.Data
        this.data = result.data.Data
      } else {
        Toast(result.data.Message)
      }
    } catch (error) {
      Toast('网络故障')
    }
  }
}

